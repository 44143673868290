@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #111111;
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(161, 161, 161, 0.712);
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1); /* Cor do polegar e fundo da barra de rolagem */
}


/*** Transition ***/
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/***** Definition Base structure Steps******/
.min-h-screen {
  min-height: 100vh;
}

.overflow-y-auto {
  overflow-y: auto;
}

/*** Definition of Mui Input Base structure ****/
.MuiSvgIcon-root{
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.MuiInputLabel-root {
  font-family: "Poppins" !important;
  color: #a8a8a8 !important;
  font-size: 16px !important;
}

@media (min-width: 1024px) {
  .MuiInputLabel-root {
    font-size: 18px !important;
  }
}

@media (min-width: 1280px) { 
  .MuiInputLabel-root {
    font-size: 20px !important;
  }
}

@media (min-width: 1536px) {
  .MuiInputLabel-root {
    font-size: 20px !important;
  }
}

.MuiFilledInput-input:-webkit-autofill {
  font-family: "Poppins" !important;
  border-radius: 8px !important;
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.MuiFilledInput-root::after {
  border: none !important;
}

.MuiFilledInput-root::before {
  border: none !important;
}

.MuiInputBase-root {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  color: rgb(235, 235, 235) !important;
  border-radius: 8px !important;
  border: none !important;
  background: rgba(255, 255, 255, 0.08);
}

.MuiInputBase-root:hover {
  background: rgba(255, 255, 255, 0.08);
}

.MuiInputBase-root label {
  font-family: "Poppins" !important;
  color: rgb(138, 138, 138) !important;
}

.MuiInputBase-root.Mui-focused {
  font-family: "Poppins" !important;
  border: 1px solid rgb(0, 144, 255) !important;
  border-radius: 8px;
  color: white;
}

/****** Effect Shake Checkbox *****/
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

/****** Custom Checkbox Geral *****/
.stroke{
  width: 1px;
  height: 20px;
  border: solid 1px #2A2A2A;
}

.custom-checkbox {
  cursor: pointer;
  appearance: none;
  background-color:rgba(255, 255, 255, 0.13);
  margin: 0;
  font: inherit;
  width: 1.4em;
  height: 1.4em;
  border: none;
  border-radius: 50px;
  display: grid;
  place-content: center;
}

.custom-checkbox::before {
  content: "";
  width: 0.7em;
  height: 0.8em;
  transform: scale(0);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  background-color: black;
  transition: transform 0.2s ease-in-out;
}

.custom-checkbox.step0:checked {
  background-color: #0090FF;
  border-color: #0090FF;
}

.custom-checkbox:checked {
  background-color: #fff;
  border-color: #fff;
}

.custom-checkbox:checked::before {
  transform: scale(1);
}

.bg-inputSelected {
  background-color: #ffffff31;
  border: 2px solid #ffffffbd;
}


/******** Custom Dialog Popup *********/
.MuiDialog-paper{
  border-radius: 16px !important;
  color: white !important;
  background: #222 !important;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.44) !important;
}

/******** Loading *********/
.icon_loading{
  color: white !important;
}

.icon_loading_login{
  color: rgb(0, 0, 0) !important;
}